import DetailNavTitle from '@businessComponents/detail-nav-title';
import React, {useRef, useState, useEffect} from 'react';
import {useInnerStyle} from './promotion.hooks';
import theme from '@style';
import {FlatList, ListRenderItemInfo, RefreshControl, View} from 'react-native';
import Card from '@basicComponents/card';
import Text from '@basicComponents/text';
import Button from '@/components/basic/button';
import {NoMoreData} from '@/components/basic/default-page';
import {PromotionListItem, getPromotionList} from './promotion.service';
import globalStore from '@/services/global.state';
import NoData from '@/components/basic/error-pages/no-data';
import {goTo, goBack} from '@/utils';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {useTranslation} from 'react-i18next';
import {goToUrl} from '@/common-pages/game-navigate';
import {LazyImageLGBackground} from '@/components/basic/image';

// const signInImg = require('@assets/imgs/promotion-sign-in.webp');

const Promotion = () => {
  const {i18n} = useTranslation();
  const {
    size: {
      screenHeight,
      itemImgWidth,
      // itemImgHeight,
      // signImgHeight,
      screenWidth,
      designWidth,
    },
    itemStyle,
    listStyle,
  } = useInnerStyle();
  const [refreshing, setRefreshing] = useState(false);

  const pageNo = useRef(1);
  const totalPage = useRef(1);

  const [promotionList, setPromotionList] = useState<PromotionListItem[]>([
    // signInPromotion,
  ]);
  const refreshPageInfo = (isMore = false) => {
    return getPromotionList(pageNo.current).then(pageInfo => {
      if (pageInfo?.content) {
        setPromotionList(
          isMore
            ? [...promotionList, ...pageInfo.content]
            : [...pageInfo.content],
        );
        totalPage.current = pageInfo.totalPages;
      }
    });
  };
  const refreshPage = (loading = true, isMore = false) => {
    loading && globalStore.globalLoading.next(true);
    pageNo.current = 1;
    refreshPageInfo(isMore).finally(() => {
      globalStore.globalLoading.next(false);
      setRefreshing(false);
    });
  };
  const refreshNextPage = () => {
    if (pageNo.current < totalPage.current) {
      pageNo.current++;
      globalStore.globalLoading.next(true);
      refreshPageInfo(true).finally(() =>
        globalStore.globalLoading.next(false),
      );
    }
  };
  useEffect(() => {
    refreshPage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const viewDetail = (item: PromotionListItem) => {
    // globalStore.asyncSetItem('promotion-detail', JSON.stringify(item));
    // goTo('PromotionDetail');
    if (!item?.activityUrl || item?.activityUrl.length === 0) {
      goTo('PromotionDetail', {id: item?.id});
      return;
    }
    goToUrl(item.activityUrl, item.activityTitle);
  };
  const renderItem = ({item, index}: ListRenderItemInfo<PromotionListItem>) => {
    // 此处先使用固定的图片，后面再根据接口替换
    return (
      <View style={[theme.margin.btml]} key={item.id}>
        <Card
          key={index}
          backgroundColor={theme.basicColor.white}
          radius={theme.borderRadiusSize.m}>
          <NativeTouchableOpacity
            onPress={() => {
              if (
                // TODO 这里不知道为什么要过滤空标签
                // (item.activityContent?.replace(/<[^>]+>/g, '').trim() ||
                //   item.activityContent?.startsWith('<img') ||
                //   item.activityContent?.startsWith('<IMG'))
                item.activityContent &&
                item.activityContent.length > 0 &&
                item.activityType !== 'signin'
              ) {
                viewDetail(item);
                return;
              }
              goToUrl(item.activityUrl, item.activityTitle);
            }}>
            <Card.Image
              width={itemImgWidth}
              height={(itemImgWidth * 115) / 343}
              // height={
              //   item.activityType === 'signin' ? signImgHeight : itemImgHeight
              // }
              imageUrl={item.activityIcon}
              radius={theme.borderRadiusSize.m}
            />
            {item.activityType === 'signin' && (
              <View
                style={[
                  theme.flex.row,
                  theme.flex.centerByCol,
                  theme.flex.between,
                  theme.padding.l,
                ]}>
                <View style={[itemStyle.leftText]}>
                  <Text
                    main
                    fontSize={theme.fontSize.m}
                    blod
                    style={[theme.margin.btmxxs]}>
                    {item.activityTitle}
                  </Text>
                  {item.activityType === 'signin' && (
                    <Text
                      accent
                      fontSize={theme.fontSize.s}
                      numberOfLines={3}
                      ellipsizeMode="tail">
                      {item.activityContent}
                    </Text>
                  )}
                </View>
                {item.activityType === 'signin' && (
                  <Button
                    titleBold
                    buttonStyle={itemStyle.button}
                    width={(screenWidth * 96) / designWidth}
                    onPress={() => {
                      goToUrl(item.activityUrl, item.activityTitle);
                    }}>
                    <Text
                      blod
                      fontSize={theme.fontSize.s}
                      color={theme.basicColor.white}>
                      {item.activityType === 'signin'
                        ? i18n.t('promotion.go')
                        : i18n.t('promotion.view')}
                    </Text>
                  </Button>
                )}
              </View>
            )}
          </NativeTouchableOpacity>
        </Card>
      </View>
    );
  };
  return (
    <LazyImageLGBackground
      style={[{height: screenHeight}, theme.background.lightGrey]}>
      <DetailNavTitle
        title={i18n.t('promotion.title')}
        hideServer
        onBack={goBack}
      />
      <FlatList
        style={[theme.flex.flex1]}
        data={promotionList}
        renderItem={renderItem}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={() => {
              setRefreshing(true);
              refreshPage(false);
            }}
          />
        }
        ListEmptyComponent={
          <View style={[theme.padding.xxl]}>
            <NoData />
          </View>
        }
        onEndReached={refreshNextPage}
        contentContainerStyle={[
          theme.padding.lrl,
          theme.padding.topl,
          listStyle.list,
        ]}
        ListFooterComponent={
          promotionList &&
          promotionList.length > 0 &&
          pageNo.current >= totalPage.current ? (
            <NoMoreData />
          ) : undefined
        }
      />
    </LazyImageLGBackground>
  );
};

export default Promotion;
